<template>


    <div class="sy1" id="main">

        <div class="sy2">
            <div style="background: rgb(22 22 23 / 58%);width: 100%;height: 100%;">

                <div class="sy3">

                    <div style="font-size: 38px;color: #fff;align-self: center; ">
                        营销推广
                    </div>
                    <!-- <div>
                    数字权益商品集成式服务应用，提供模块化虚拟商 <br>
                    城搭建服务，帮助企业自助配置商品、定价 、UI<br>
                    模板及支付方式，快速创建属于自己的虚拟商城。
                </div> -->
                </div>
            </div>

        </div>

        <div class="sy4">
            <span>
                自2022年成立至今，一直致力在虚拟服务行业沉淀，潜心<br>
                打造的全产业链虚拟增值服务平台连接数字权益商品提供商、消费场景和消费者。 <br>
                技术实现、场景营销。为客户企业销售提供一站式服务解决方案。
            </span>

        </div>

        <div class="sy6">

            <div class="sy7">
                <div class="sy8">
                    <span>
                        虚拟产品行业授权电商
                    </span>
                    <div style="margin-top: 10px;">
                        整合虚拟类商品上下游产业生态，从量级供应、技术服务、产品运营、营销推 <br> 广等维度为企业提供一站式解决方案
                    </div>
                </div>

                <div class="sy10">
                    <div class="sy9">
                        <img src="../assets/m1.png" alt="" srcset="">
                        <div>
                            京东
                        </div>
                    </div>
                    <div class="sy9">
                        <img src="../assets/m2.png" alt="" srcset="">
                        <div>
                            拼多多
                        </div>
                    </div>
                    <div class="sy9">
                        <img src="../assets/m3.png" alt="" srcset="">
                        <div>
                            抖音
                        </div>
                    </div>
                    <div class="sy9">
                        <img src="../assets/m4.png" alt="" srcset="" width="72px" style="border-radius: 37px;">
                        <div>
                            淘宝
                        </div>
                    </div>
                    <div class="sy9">
                        <img src="../assets/m5.png" alt="" srcset="" width="72px" style="border-radius: 37px;">
                        <div>
                            闲鱼
                        </div>
                    </div>
                </div>


            </div>

        </div>

        <div class="sy7 sy11">
            <div class="" style="margin-bottom: 37px;">
                <div class="sy13">
                    业务优势
                </div>
                <div class="sy14">
                    1.策略支持 创意提供
                </div>
                <div>
                    为合作企业提供整合创意营销解决方案，包括不仅限于营销策略、内容 <br> 创意、数据化运营等服务。
                </div>
            </div>
            <div class="sy15">

                <div class="sy16"><img src="../assets/m6.png" alt="" srcset="">
                    <h2> 互动营销</h2>
                    <div> 沉默用户唤醒、日常活动促进、消费转化引导等</div>
                </div>
                <div class="sy16"><img src="../assets/m7.png" alt="" srcset="">
                    <h2> 事件推广</h2>
                    <div> 借力社会性营销时间，帮助巩固产品品牌形象口碑</div>
                </div>
                <div class="sy16"><img src="../assets/m8.png" alt="" srcset="">
                    <h2> 社交流量引流</h2>
                    <div>根据不同平台用户特性，借助流量趋势，持续帮助产品进入销售场景</div>
                </div>
            </div>
        </div>


        <div class="sy11" style="background: #F7F7F8;width: 100%;padding-top: 75px;">
            <div style="width: 1200px;margin: 0px auto;">

                <div class="" style="margin-bottom: 37px;">
                    <div class="sy14">
                        2.资源整合 流量变现
                    </div>
                    <div>
                        整合多渠道头部流量入口，为企业提供数字化营销推广服务，助力实现 <br> 精准导流与销售转化。
                    </div>
                </div>
                <div class="sy17">

                    <div class="sy16"><img src="../assets/m9.png" alt="" srcset="">
                        <h2> 互动营销</h2>
                        <div> 沉默用户唤醒、日常活动促进、消费转化引导等</div>
                    </div>
                    <div class="sy16"><img src="../assets/m10.png" alt="" srcset="">
                        <h2> 事件推广</h2>
                        <div> 借力社会性营销时间，帮助巩固产品品牌形象口碑</div>
                    </div>
                    <div class="sy16"><img src="../assets/m11.png" alt="" srcset="">
                        <h2> 社交流量引流</h2>
                        <div>根据不同平台用户特性，借助流量趋势，持续帮助产品进入销售场景</div>
                    </div>
                </div>
            </div>

        </div>


        <div class="all1">

            <div class="all2">

                <div class="all3">

                    <div>
                        拔刀科技
                    </div>

                    <div class="all4">
                        合作 ▪ 分享 ▪ 共赢
                    </div>
                </div>

                <div class="all5">
                    <div class="all6">
                        <!-- 周口拔刀网络科技有限公司 -->
                        周口拔刀网络科技有限公司
                    </div>
                    <div class="all4" style="display: flex;">
                        <div>
                            <i class="el-icon-office-building"></i>
                        </div>
                        <div>
                            河南省郑州市金水区金水路305号1号楼2单元 <br>25层西户
                        </div>
                    </div>
                    <div class="all4">
                        <i class="el-icon-phone-outline"></i>
                        <span>
                            15136250351
                        </span>
                    </div>
                </div>
            </div>

            <div class="all17">
                Copyright © 周口拔刀网络科技有限公司 <span @click="put">豫ICP备2023002840号-1</span>
            </div>
        </div>


    </div>


</template>
<script>

export default {
    name: 'introduce',
    components: {
    },

    data() {
        return {

        }
    },
    mounted() {
        document.getElementById("main").scrollIntoView({
            behavior: "smooth",
            block: "start",
        });
    },

    methods: {
        put() {
            window.open('https://beian.miit.gov.cn/')
        },
        routpush(val) {
            console.log(val, "vlavlalv");
            this.$router.push(val)
        },
    }
}

</script>
<style lang="less" scoped>
.sy1 {
    width: 100%;
    height: 100%;
    // height: 3000px;

}

.sy2 {
    margin: 0 auto;
    width: 100%;
    height: 700px;
    background-image: url(../assets/y1.jpg);
    // background-repeat: repeat-x;
    // background-size: 100% 100%;
    background-repeat: no-repeat;
}

.sy3 {
    font-size: 22px;
    color: #FFFFFF;
    font-weight: 600;
    // padding-left: 41%;
    // padding-top: 20%;
    height: 100%;
    display: flex;
    justify-content: center;
    // align-content: center;

}

.sy4 {
    width: 100%;
    background: #fff;
    display: flex;
    justify-content: space-around;
    text-align: center;
    padding: 50px 260px;

    span {
        font-size: 22px;
        color: #000;
        letter-spacing: 1px;
        font-weight: 500;
    }
}



.sy6 {
    width: 100%;
    height: 658px;
    background: #F1F1F8;
}

.sy7 {
    max-width: 1200px;
    margin: 0 auto;
}

.sy8 {
    text-align: center;
    color: #000000;
    padding-top: 70px;
    padding-bottom: 30px;

    >span {
        font-size: 36px;
        font-weight: 800;
    }

    >div {
        color: #8D8B8B;
        line-height: 26px;
    }
}

.sy10 {
    width: 100%;
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
}

.sy9 {
    text-align: center;
    // padding: 60px 40px;
    background: #fff;
    border-radius: 20px;
    margin-bottom: 30px;
    width: 18%;
    height: 326px;

    img {
        margin-top: 80px;
    }

    div {
        font-weight: 800;
        font-size: 23px;
        color: #000;
        margin-top: 24px;
    }
}

.sy9:hover {
    margin-top: 0px;
    /*和hover的margin-top有对比，原无30,现在0，相当于上移了,30px*/
    box-shadow: 0 0 18px 2px #b6b4b4;
    /*盒子阴影*/
    transition: all 0.4s;
    /*持续时间*/
}

.sy11 {
    text-align: center;
    padding-bottom: 47px;
}

.sy13 {
    color: #000000;
    font-weight: bold;
    font-size: 35px;
    margin-top: 90px;
    margin-bottom: 50px;
}

.sy14 {
    color: #000000;
    font-size: 26px;
    font-weight: 600;
    margin-bottom: 15px;
}

.sy15 {
    width: 79%;
    margin: 0px auto;
    display: flex;
    justify-content: space-around;
}

.sy16 {
    padding: 60px 40px;
    background: #fff;
    border-radius: 20px;
    margin-bottom: 30px;
    border: 1px solid #ECECEC;
    width: 260px;
    height: 396px;

    h2 {
        color: #000;
        margin: 28px 0px;
    }
}

.sy17 {

    width: 79%;
    margin: 0px auto;
    display: flex;
    justify-content: space-around;
}

.all1 {
    // margin-top: 60px;
    background: #262431;
    height: 300px;
}

.all2 {
    width: 1000px;
    height: 86%;
    color: #F1F1F8;
    margin: 0px auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.all3 {
    font-size: 18px;
    font-weight: 600;

}

.all4 {
    margin-top: 16px;

    i {
        padding-right: 10px;
    }

    span {
        font-size: 15px;
    }
}

.all5 {}

.all6 {
    font-size: 20px;
    font-weight: 600;
}

.all17 {
    font-size: 13px;
    color: #f1f1f8;
    text-align: center;
    opacity: 0.6;

    span {
        cursor: pointer;
    }
}
</style>
