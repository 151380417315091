var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"sy1",attrs:{"id":"main"}},[_vm._m(0),_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_c('div',{staticClass:"all1"},[_vm._m(5),_c('div',{staticClass:"all17"},[_vm._v(" Copyright © 周口拔刀网络科技有限公司 "),_c('span',{on:{"click":_vm.put}},[_vm._v("豫ICP备2023002840号-1")])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"sy2"},[_c('div',{staticStyle:{"background":"rgb(22 22 23 / 58%)","width":"100%","height":"100%"}},[_c('div',{staticClass:"sy3"},[_c('div',{staticStyle:{"font-size":"38px","color":"#fff","align-self":"center"}},[_vm._v(" 营销推广 ")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"sy4"},[_c('span',[_vm._v(" 自2022年成立至今，一直致力在虚拟服务行业沉淀，潜心"),_c('br'),_vm._v(" 打造的全产业链虚拟增值服务平台连接数字权益商品提供商、消费场景和消费者。 "),_c('br'),_vm._v(" 技术实现、场景营销。为客户企业销售提供一站式服务解决方案。 ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"sy6"},[_c('div',{staticClass:"sy7"},[_c('div',{staticClass:"sy8"},[_c('span',[_vm._v(" 虚拟产品行业授权电商 ")]),_c('div',{staticStyle:{"margin-top":"10px"}},[_vm._v(" 整合虚拟类商品上下游产业生态，从量级供应、技术服务、产品运营、营销推 "),_c('br'),_vm._v(" 广等维度为企业提供一站式解决方案 ")])]),_c('div',{staticClass:"sy10"},[_c('div',{staticClass:"sy9"},[_c('img',{attrs:{"src":require("../assets/m1.png"),"alt":"","srcset":""}}),_c('div',[_vm._v(" 京东 ")])]),_c('div',{staticClass:"sy9"},[_c('img',{attrs:{"src":require("../assets/m2.png"),"alt":"","srcset":""}}),_c('div',[_vm._v(" 拼多多 ")])]),_c('div',{staticClass:"sy9"},[_c('img',{attrs:{"src":require("../assets/m3.png"),"alt":"","srcset":""}}),_c('div',[_vm._v(" 抖音 ")])]),_c('div',{staticClass:"sy9"},[_c('img',{staticStyle:{"border-radius":"37px"},attrs:{"src":require("../assets/m4.png"),"alt":"","srcset":"","width":"72px"}}),_c('div',[_vm._v(" 淘宝 ")])]),_c('div',{staticClass:"sy9"},[_c('img',{staticStyle:{"border-radius":"37px"},attrs:{"src":require("../assets/m5.png"),"alt":"","srcset":"","width":"72px"}}),_c('div',[_vm._v(" 闲鱼 ")])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"sy7 sy11"},[_c('div',{staticStyle:{"margin-bottom":"37px"}},[_c('div',{staticClass:"sy13"},[_vm._v(" 业务优势 ")]),_c('div',{staticClass:"sy14"},[_vm._v(" 1.策略支持 创意提供 ")]),_c('div',[_vm._v(" 为合作企业提供整合创意营销解决方案，包括不仅限于营销策略、内容 "),_c('br'),_vm._v(" 创意、数据化运营等服务。 ")])]),_c('div',{staticClass:"sy15"},[_c('div',{staticClass:"sy16"},[_c('img',{attrs:{"src":require("../assets/m6.png"),"alt":"","srcset":""}}),_c('h2',[_vm._v(" 互动营销")]),_c('div',[_vm._v(" 沉默用户唤醒、日常活动促进、消费转化引导等")])]),_c('div',{staticClass:"sy16"},[_c('img',{attrs:{"src":require("../assets/m7.png"),"alt":"","srcset":""}}),_c('h2',[_vm._v(" 事件推广")]),_c('div',[_vm._v(" 借力社会性营销时间，帮助巩固产品品牌形象口碑")])]),_c('div',{staticClass:"sy16"},[_c('img',{attrs:{"src":require("../assets/m8.png"),"alt":"","srcset":""}}),_c('h2',[_vm._v(" 社交流量引流")]),_c('div',[_vm._v("根据不同平台用户特性，借助流量趋势，持续帮助产品进入销售场景")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"sy11",staticStyle:{"background":"#F7F7F8","width":"100%","padding-top":"75px"}},[_c('div',{staticStyle:{"width":"1200px","margin":"0px auto"}},[_c('div',{staticStyle:{"margin-bottom":"37px"}},[_c('div',{staticClass:"sy14"},[_vm._v(" 2.资源整合 流量变现 ")]),_c('div',[_vm._v(" 整合多渠道头部流量入口，为企业提供数字化营销推广服务，助力实现 "),_c('br'),_vm._v(" 精准导流与销售转化。 ")])]),_c('div',{staticClass:"sy17"},[_c('div',{staticClass:"sy16"},[_c('img',{attrs:{"src":require("../assets/m9.png"),"alt":"","srcset":""}}),_c('h2',[_vm._v(" 互动营销")]),_c('div',[_vm._v(" 沉默用户唤醒、日常活动促进、消费转化引导等")])]),_c('div',{staticClass:"sy16"},[_c('img',{attrs:{"src":require("../assets/m10.png"),"alt":"","srcset":""}}),_c('h2',[_vm._v(" 事件推广")]),_c('div',[_vm._v(" 借力社会性营销时间，帮助巩固产品品牌形象口碑")])]),_c('div',{staticClass:"sy16"},[_c('img',{attrs:{"src":require("../assets/m11.png"),"alt":"","srcset":""}}),_c('h2',[_vm._v(" 社交流量引流")]),_c('div',[_vm._v("根据不同平台用户特性，借助流量趋势，持续帮助产品进入销售场景")])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"all2"},[_c('div',{staticClass:"all3"},[_c('div',[_vm._v(" 拔刀科技 ")]),_c('div',{staticClass:"all4"},[_vm._v(" 合作 ▪ 分享 ▪ 共赢 ")])]),_c('div',{staticClass:"all5"},[_c('div',{staticClass:"all6"},[_vm._v(" 周口拔刀网络科技有限公司 ")]),_c('div',{staticClass:"all4",staticStyle:{"display":"flex"}},[_c('div',[_c('i',{staticClass:"el-icon-office-building"})]),_c('div',[_vm._v(" 河南省郑州市金水区金水路305号1号楼2单元 "),_c('br'),_vm._v("25层西户 ")])]),_c('div',{staticClass:"all4"},[_c('i',{staticClass:"el-icon-phone-outline"}),_c('span',[_vm._v(" 15136250351 ")])])])])
}]

export { render, staticRenderFns }